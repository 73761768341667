<template>
    <div class="dashboard" :style="`--var-element-color: ${ workoutPlan.public_settings.element_color }; --var-element-text-color: ${ workoutPlan.public_settings.element_text_color }; --var-timer-color: ${ workoutPlan.public_settings.timer_color }; --var-submit-btn-color: ${workoutPlan.public_settings.submit_btn_bgcolor}; --var-submit-btn-txt-color: ${workoutPlan.public_settings.submit_btn_textcolor}; --var-block-bg-color: ${workoutPlan.public_settings.block_bg_color}`">
        <div class="client_card" v-if="tab == 'plan'">
            <div v-show="publicSettings.display_header == 1" class="card_header pointer"  ref="hdrsection" :style="`background: ${publicSettings.header_bgcolor}; color: ${publicSettings.header_textcolor};`">
                <nav>
                    <button type="button" class="back_btn" v-if="tab != 'plan'"><i class="fas fa-chevron-left"></i>Leave</button>
                    <img class="logo_img" v-if="publicSettings.display_logo" :src="publicSettings.logo ? publicSettings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a>
                        <h4>Dashboard</h4>
                    </a>
                </nav>
            </div>
        </div>
        <div class="dashboard_content" v-if="tab == 'plan'">
            <div class="form_area_video">
                <div class="section_item mb-3" ref="expvidsection" v-if="publicSettings.display_explainer_video && ((publicSettings.explainer_video && publicSettings.explainer_video_type == 0) || (publicSettings.explainer_embed && publicSettings.explainer_video_type == 1))">
                    <div class="video_wpr">
                        <div v-if="publicSettings.explainer_video_type == 0" v-html="parseEmbedCode(publicSettings.explainer_video)"></div>
                        <div v-if="publicSettings.explainer_video_type == 1" v-html="publicSettings.explainer_embed"></div>
                    </div>
                </div>
            </div>
            <div class="dashboard_container">
                <div class="exercise_list" v-if="tab == 'plan'">
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">{{ workoutPlan.name }}</h4>
                            <p>{{ workoutPlan.description }}</p>
                            <div class="quick_guide">
                                <ul>
                                    <li>
                                        <h5>{{ workoutPlan.workouts_count ? workoutPlan.workouts_count : 0 }}</h5>
                                        <h6>Workouts</h6>
                                    </li>
                                    <li>
                                        <h5>{{ workoutPlan.blocks_count ? workoutPlan.blocks_count : 0 }}</h5>
                                        <h6>Blocks</h6>
                                    </li>
                                    <li>
                                        <h5>{{ workoutWeeks.length ? workoutWeeks.length : 0 }} Weeks</h5>
                                        <h6>Duration</h6>
                                    </li>
                                </ul>
                                <button type="button" class="start_btn" @click="handleStartPlan()">{{completedWeek > 0 ? 'Resume Program' : 'Start Program'}}</button>
                            </div>
                        </div>
                        <div class="week_list">
                            <div class="day_guide">
                                <label><span class="active m-0"></span>Work Day</label>
                                <label><span class="m-0"></span>Rest Day</label>
                            </div>
                            <ul>
                                <li v-for="(week, w) of workoutWeeks" :key="w" :class="{'completed': completedWeek >= w + 1}">
                                    <span class="bullet_icon">{{w + 1}}</span>
                                    <div class="exercise_info">
                                        <h4>Week {{w + 1}} <span>{{ getWeekDates(week, w) }}</span></h4>
                                        <div class="week_days_list">
                                            <label v-for="(setting, s) of week.week_settings" :key="s" @click="setting.has_workout && setting.workouts.length ? startPlan(w, setting.day) : handleNoWorkoutAlert(setting)">
                                                <div class="item_wpr" :class="{'present_day': (new Date().getDay() == setting.day) && completedWeek == w}">
                                                    <span class="day_stat" :class="{'active': setting.has_workout}"></span>
                                                    <span class="weekdays capitalize">{{ setting.title.substr(0, 3) }}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="dashboard_footer" v-show="publicSettings.display_footer && tab == 'plan'" :style="`background: ${publicSettings.footer_bgcolor};`">
            <ul>
                <li class="pointer" v-if="publicSettings.footer_has_facebook"><a :href="publicSettings.footer_facebook" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer" v-if="publicSettings.footer_has_twitter"><a :href="publicSettings.footer_twitter" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                <li class="pointer" v-if="publicSettings.footer_has_instagram"><a :href="publicSettings.footer_instagram" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                <li class="pointer" v-if="publicSettings.footer_has_linkedin"><a :href="publicSettings.footer_linkedin" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                <li class="pointer" v-if="publicSettings.footer_has_youtube"><a :href="publicSettings.footer_youtube" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
            </ul>
            <h4 :style="`color: ${publicSettings.footer_textcolor};`">© {{ publicSettings.footer_company }}</h4>
            <p>
                <a href="javascript:void(0);" :style="`color: ${publicSettings.footer_textcolor};`">Terms</a>
                <a href="javascript:void(0);" :style="`color: ${publicSettings.footer_textcolor};`">Privacy</a>
                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                    <a v-if="whiteLabel.has_link == 1" :style="`color: ${publicSettings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    <a v-else :style="`color: ${publicSettings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                </template>
                <a v-else :style="`color: ${publicSettings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
            </p>
        </footer>
    </div>
    <plan-workout-preview v-if="tab == 'workout-detail'" :workout="selectedWorkout" :public-settings="workoutPlan.public_settings"></plan-workout-preview>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState } from 'vuex'

    const PlanWorkoutPreview = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanWorkoutPreview'))

    import moment from 'moment-timezone'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Workout Plan Public Preview',

        data () {
            return {
                tab: 'plan',
                completedWeek: 0,
                selectedWeek: 0,
                selectedDay: new Date().getDay(),
                moment,
                startScreen: false,
                workoutStarted: false,
                selectedWorkout: {},
                activeWorkoutDay: [],
                whiteLabel: '',
            };
        },

        props: {
            workoutPlan: Object,
            workoutWeeks: Object,
            publicSettings: Object,
            isPlan: {
                type: Boolean,
                default: false,
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        components: {
            PlanWorkoutPreview,
        },

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
        },

        methods: {
            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            startPlan (week, weekDay) {
                const vm = this;

                const hour  = vm.user.timezone ? moment().tz(vm.user.timezone).format('H') : moment().format('H');
                let time    = 'AM';

                if (hour < 12 ) {
                    time = 'AM';
                } else if (hour >= 12 && hour < 17) {
                    time = 'Mid';
                } else if (hour >= 17 && hour < 24) {
                    time = 'PM';
                }

                vm.selectedWeek     = week;
                vm.selectedDay      = weekDay;
                vm.activeWorkoutDay = vm.workoutWeeks[vm.selectedWeek].week_settings.find(({day}) => day == weekDay);

                let index = 0;

                if (vm.activeWorkoutDay.workouts.length > 1) {
                    index = vm.activeWorkoutDay.workouts.findIndex(workout => workout.period === time);
                }

                vm.handleStartWorkout (week, weekDay - 1, index ? index : 0);
            },

            getWeekDates(week, w) {
                const vm          = this;
                const startOfWeek = !week.is_week_start_sunday ? moment().add(w, 'weeks').startOf('week').add(1, 'day') : moment().add(w, 'weeks').startOf('week');
                const endOfWeek   = !week.is_week_start_sunday ? moment().add(w, 'weeks').endOf('week').add(1, 'day') : moment().add(w, 'weeks').endOf('week');

                return `${startOfWeek.format('MMM DD')} - ${startOfWeek.format('MMM') !== endOfWeek.format('MMM') ? endOfWeek.format('MMM') : ''} ${endOfWeek.format('DD')}`;
            },

            handleStartPlan () {
                const vm            = this;
                const weekCount     = vm.workoutWeeks && vm.workoutWeeks.length ? vm.workoutWeeks.length : 0;
                const currentDay    = new Date().getDay();
                let hasWorkout      = false;

                for (let index = 0; index < weekCount; index++) {
                    const weekDays = vm.workoutPlan.weeks[index].week_settings;
                    const day      = weekDays.find((day) => day.has_workout && day.workouts && day.workouts.length && day.day == currentDay);

                    if (day) {
                        vm.startPlan(index, day.day);
                        hasWorkout = true;
                        break;
                    }
                }

                if (!hasWorkout) {
                    const options = Helper.swalWarningOptions('Oops!', 'No workout found for today!');

                    Swal.fire(options);
                }
            },

            handleStartWorkout (week, day, index) {
                const vm = this;

                vm.selectedWorkout = vm.workoutWeeks[week].week_settings[day].workouts[index];
                vm.tab = 'workout-detail';
            },

            handleNoWorkoutAlert (setting) {
                if (setting.has_workout) {
                    const options = Helper.swalWarningOptions('Oops!', 'No workout found for this day!');

                    Swal.fire(options);
                }
            },
        },
    }
</script>

<style scoped>
    .dashboard{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .dashboard_content {
        background: #fff;
        margin: 0;
        padding: 40px 15px 0 15px;
        flex-grow: 1;
    }

    .tab .dashboard_content,
    .desk .dashboard_content {
        max-width: none;
        margin: 0;
        padding: 0 30px;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .desk .logo_img,
    .tab .logo_img {
        max-height: 30px;
    }

    .client_card {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #e9e9e9;
    }
    .client_card .card_header .user_box > img{
        width: 20px;
        height: 20px;
    }
    .desk .client_card .card_header .user_box > img, .tab .client_card .card_header .user_box > img{
        width: 25px;
        height: 25px;
    }
    .client_card .card_header .user_box h5{
        font-size: 11px;
        line-height: 14px;
    }
    .desk .client_card .card_header, .tab .client_card .card_header{
        padding: 15px 20px;
        background: #fff;
    }
    .cell .client_card .card_header{
        padding: 15px
    }
    .cell .client_card .card_header nav{
        margin-right: 0;
        padding-right: 0;
        border: 0;
    }
    .cell .client_card .card_header nav a{
        margin-left: auto;
    }

    .dashboard_footer {
        margin: 0 -15px;
    }

    .dashboard_container {
        flex: 0 1 800px;
        margin: 0 auto;
    }

    .back_btn {
        width: 20px;
        height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .card_details {
        position: absolute;
        background: #fff;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }

    .card_details.active {
        max-height: 100%;
        top: 0;
        overflow-Y: scroll;
    }

    .card_details::-webkit-scrollbar {
        display: none;
    }

    .card_details .deatils_header {
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .card_details .exercise_details {
        padding: 30px 15px;
    }

    .desk .card_details .exercise_details,
    .tab .card_details .exercise_details {
        padding: 50px 30px;
    }

    .rep_confirmation {
        text-align: center;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 0;
        height: auto;
        overflow: hidden;
        z-index: 4;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }

    .rep_confirmation.active {
        box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.15);
        max-height: 610px;
        height: auto;
        overflow: visible;
    }

    .rep_confirmation .setting_wpr {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        padding: 25px;
        background: #fff;
        border-radius: 6px 6px 0 0;
    }

    .rep_confirmation .setting_wpr h3 {
        font-size: 15px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        text-align: left;
        padding-bottom: 5px;
    }

    .rep_confirmation .field_wpr input,
    .rep_confirmation .form_grp .multiselect {
        height: 40px;
        min-height: 40px;
    }

    .rep_confirmation .form_grp .multiselect :deep(.multiselect-single-label-text) {
        font-size: 13px;
    }

    .rep_confirmation .setting_wpr .primary_btn {
        margin: 10px 0;
        cursor: pointer;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        height: 45px;
        letter-spacing: 0.5px;
    }

    .tab .rep_confirm_box,
    .cell .rep_confirm_box {
        display: none;
    }

    .rep_confirm_box {
        padding: 20px;
        min-width: 250px;
        left: auto;
        right: 0;
        margin: 0;

    }

    .rep_confirm_box .setting_wpr h3 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        text-align: left;
        padding-bottom: 5px;
        margin: 0;
    }

    .rep_confirm_box .field_wpr input,
    .rep_confirm_box .form_grp .multiselect {
        height: 40px;
        min-height: 40px;
    }

    .rep_confirm_box .form_grp .multiselect :deep(.multiselect-single-label-text) {
        font-size: 13px;
    }

    .rep_confirm_box .setting_wpr .primary_btn {
        margin: 10px 0;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        height: 45px;
        letter-spacing: 0.5px;
    }

    /*------- Plan --------*/

    .exercise_list {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .exercise_item .ex_title {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .quick_guide {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .quick_guide ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }

    .desk .quick_guide ul,
    .tab .quick_guide ul {
        padding: 40px 0;
    }

    .quick_guide ul li {
        padding: 0 25px;
        border-right: 1px solid #d9d9d9;
    }

    .desk .quick_guide ul li,
    .tab .quick_guide ul li {
        padding: 0 40px;
    }

    .quick_guide ul li:last-child {
        border: 0;
    }

    .quick_guide ul li h5 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: center;
    }

    .desk .quick_guide ul li h5,
    .tab .quick_guide ul li h5 {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 7px;
    }

    .quick_guide ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
    }

    .desk .quick_guide ul li h6,
    .tab .quick_guide ul li h6 {
        font-size: 13px;
        line-height: 16px;
    }

    .start_btn {
        min-width: 200px;
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
        height: 45px;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 21px;
        cursor: pointer;
        padding: 0 15px;
        margin: 10px auto;
    }

    .desk .start_btn,
    .tab .start_btn {
        min-width: 250px;
        height: 55px;
        font-size: 18px;
        line-height: 21px;
        margin: 20px auto;
    }

    .week_list {
        padding: 20px 0;
    }

    .week_list .day_guide {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 0 0 20px 0;
    }

    .week_list .day_guide label {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 7px;
    }

    .desk .week_list .day_guide label,
    .tab .week_list .day_guide label {
        font-size: 13px;
        line-height: 16px;
        gap: 10px;
    }

    .week_list ul {
        position: relative;
        padding-bottom: 30px;
    }

    .week_list ul:before {
        position: absolute;
        content: '';
        left: 11px;
        top: 60px;
        bottom: 60px;
        border-left: 1px solid #d9d9d9;
    }

    .desk .week_list ul:before,
    .tab .week_list ul:before {
        left: 14px;
    }

    .week_list ul li {
        padding: 10px 0;
        display: flex;
        align-items: center;
        position: relative;
    }

    .week_list ul li:before {
        position: absolute;
        content: '';
        right: 1px;
        left: 1px;
        top: calc(50% - 1px);
        border-bottom: 1px solid #d9d9d9;
    }

    .week_list ul li .bullet_icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        border-radius: 50%;
        background: #e9e9e9;
        border: 1px solid #d9d9d9;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin-right: 10%;
    }

    .desk .week_list ul li .bullet_icon,
    .tab .week_list ul li .bullet_icon {
        width: 30px;
        height: 30px;
    }

    .exercise_item .exercise_info {
        flex: 0 1 580px;
        margin-left: auto;
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }

    .exercise_item ul li.completed {
        pointer-events: none;
    }

    .exercise_item ul li.completed .exercise_info {
        filter: grayscale(1);
    }

    .exercise_item .exercise_info h4 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .exercise_item .exercise_info h4 span {
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }

    .week_days_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
        position: relative;
    }

    .week_days_list label {
        flex: 1 1 30px;
    }

    .week_days_list label .item_wpr {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }

    .week_days_list label .item_wpr.present_day {
        border: 1px solid #bebebe;
    }

    .week_days_list label span {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .desk .week_days_list label span,
    .tab .week_days_list label span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
    }

    .week_days_list label .day_stat,
    .week_list .day_guide label span {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #c1c1c1;
        position: relative;
        margin-top: 7px;
    }

    .week_days_list label .day_stat.active,
    .week_list .day_guide label span.active {
        background: var(--var-element-color);
    }

    .exercise_item ul li.completed .week_days_list:before {
        position: absolute;
        content: '';
        left: 15px;
        right: 15px;
        border-top: 1px solid #bebebe;
    }

    .exercise_item ul li.completed .week_days_list label .day_stat.active:before {
        position: absolute;
        content: '';
        left: 5px;
        bottom: 100%;
        height: 14px;
        border-left: 1px solid #bebebe;
    }

    .exercise_item ul li.completed .week_days_list label .day_stat.active:last-of-type:after {
        position: absolute;
        content: '';
        left: 5px;
        top: -10px;
        width: 250px;
        border-top: 1px solid #bebebe;
    }

    .timer {
        text-align: center;
        position: sticky;
        bottom: 0;
        z-index: 4;
        background: #fff;
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        height: 100%;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }

    .timer.active {
        padding: 15px;
        box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.15);
        overflow: visible;
        max-height: 150px;
        height: auto;
        bottom: 0;
    }

    .timer.active.lg {
        border-radius: 0;
        max-height: 610px;
        height: 100%;
        overflow-y: scroll;
    }

    .desk .timer.active.lg {
        max-height: calc(100vh - 75px);
    }

    .tab .timer.active.lg {
        max-height: 1050px;
    }

    .timer.active.lg::-webkit-scrollbar {
        display: none;
    }

    .timer .alert {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;
        width: calc(100% + 30px);
        margin: -5px -15px 15px -15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timer .alert i {
        font-size: 16px;
        margin-left: 15px;
        color: #999;
        animation: mover2 0.5s infinite alternate;
    }

    .toggle_box {
        font-size: 16px;
        color: #777;
        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;
    }

    .timer .counter {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #5a5a5a;
        position: relative;
    }

    .desk .timer .counter,
    .tab .timer .counter {
        font-size: 20px;
        line-height: 25px;
    }

    .timer .counter h6 {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 3px;
    }

    .desk .timer .counter h6,
    .tab .timer .counter h6 {
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 7px;
    }

    .timer .counter h6 button {
        font-size: 9px;
        line-height: 12px;
        border-radius: 3px;
        padding: 2px 5px;
        color: var(--var-submit-btn-txt-color);
        background: var(--var-submit-btn-color);
        margin-right: 5px;
    }

    .timer .counter span {
        color: var(--var-timer-color);
    }

    .timer .other_counters {
        display: flex;
        padding: 0 0 5px;
    }

    .desk .timer .other_counters,
    .tab .timer .other_counters {
        padding: 0 0 10px;
    }

    .timer .other_counters .counter {
        padding: 0 20px;
    }

    .timer .other_counters .counter:not(:first-of-type) {
        border-left: 1px solid #d9d9d9;
    }

    .timer .success {
        color: var(--var-element-color);
        font-size: 30px;
        padding-bottom: 10px;
    }

    .timer .copyright {
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #999;
        margin-top: auto;
    }

    .rest_alert {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 10px 20px;
        position: relative;
    }

    .rest_alert label.capsule_btn {
        position: absolute;
        right: 0;
        top: 20px;
    }

    .rest_alert .score_circle {
        width: 160px;
        height: 160px;
        padding: 6px;
    }

    .desk .rest_alert .score_circle,
    .tab .rest_alert .score_circle {
        margin: 10px 0;
    }

    .rest_alert .score_circle svg {
        pointer-events: none;
    }

    .rest_alert .score_circle svg circle {
        transition: all 0.3s ease-in-out;
    }

    .rest_alert .score_circle .inner_circle {
        background: #fff;
    }

    .rest_alert .score_circle .inner_circle .score {
        font-size: 22px;
        line-height: 28px;
        font-weight: 300;
        color: #121525;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .rest_alert h5 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
    }

    .desk .rest_alert h5,
    .tab .rest_alert h5 {
        font-size: 18px;
        line-height: 25px;
    }

    .play_pause_btn {
        font-size: 10px;
        color: #757575;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: calc(50% - 10px);
        margin-top: 10px;
        cursor: pointer;
    }

    .play_pause_btn i.fa-play {
        margin-left: 3px;
    }

    .timer .counter .play_pause_btn {
        left: 100%;
        top: auto;
        bottom: 3px;
    }

    .rest_alert .score_circle .inner_circle .score.go {
        width: 50px;
        height: 50px;
        background: #fff;
    }

    .rest_alert .score_circle .inner_circle .score.go:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 1s infinite alternate;
    }

    .rest_alert .score_circle .inner_circle .score .hand_icon {
        position: absolute;
        left: calc(50% - 10px);
        top: 100%;
        margin-top: 5px;
        font-size: 25px;
        color: #2c3e50;
        animation: mover 1s infinite alternate;
    }

    .timer .rounds {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 7px 0;
    }

    .desk .timer .rounds,
    .tab .timer .rounds {
        gap: 20px;
        font-size: 15px;
        line-height: 20px;
        padding: 10px 0;
    }

    .timer .rounds ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }

    .timer .rounds ul li {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #fff;
        background: #888;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .desk .timer .rounds ul li,
    .tab .timer .rounds ul li {
        width: 25px;
        height: 25px;
        font-size: 13px;
        line-height: 15px;
    }

    .timer .rounds ul li.active {
        background: var(--var-timer-color);
    }

    .timer .exercises {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 10px 0;
    }

    .desk .timer .exercises,
    .tab .timer .exercises {
        gap: 20px;
        font-size: 15px;
        line-height: 20px;
        padding: 15px 0;
    }

    .timer .exercises ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .timer .exercises ul li {
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background: #999;
        position: relative;
    }

    .desk .timer .exercises ul li,
    .tab .timer .exercises ul li {
        width: 30px;
        height: 6px;
        border-radius: 3px;
    }

    .timer .exercises ul li.blink {
        background: #fff;
    }

    .timer .exercises ul li.blink:after {
        position: absolute;
        content: '';
        border-radius: 3px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim2 0.5s infinite alternate;
    }

    .timer .exercises ul li.active {
        background: var(--var-timer-color);
    }

    .timer .exercise_details {
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
    }

    .desk .exercise_details {
        max-width: 990px;
        padding: 0 15px;
        margin: 0 auto;
    }

    .desk .timer .exercise_details {
        max-width: 600px;
    }

    .timer .skip_btn {
        font-size: 11px;
        line-height: 14px;
        padding: 4px 10px;
        font-weight: 500;
        color: #121525;
        position: absolute;
        right: 15px;
        bottom: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .timer .back_btn {
        width: auto;
        height: auto;
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        padding: 4px 10px;
        position: absolute;
        left: 15px;
        bottom: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .desk .timer .skip_btn,
    .tab .timer .skip_btn {
        font-size: 13px;
        line-height: 16px;
        padding: 5px 10px;
        bottom: 10px;
    }

    .desk .timer .back_btn,
    .tab .timer .back_btn {
        font-size: 13px;
        line-height: 16px;
        padding: 5px 10px;
        bottom: 10px;
    }

    /* ------- workout start screen ------------ */

    .startScreen {
        justify-content: center;
        z-index: 5;
    }

    .block_stat {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1px;
        margin-bottom: 50px;
    }

    .block_stat li {
        background: #dbdbdb;
        padding: 8px 20px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        border-radius: 2px;
        transition: all 0.3s ease-in-out;
        position: relative;
    }

    .desk .block_stat li,
    .tab .block_stat li {
        font-size: 13px;
        line-height: 18px;
        padding: 10px 25px;
    }

    .block_stat li.active {
        background: #2f7eed;
        color: #fff;
    }

    .block_stat li.blink {
        background: #fff;
    }

    .block_stat li.blink:after {
        position: absolute;
        content: '';
        border-radius: 3px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 0.5s infinite alternate;
    }

    .timer .welcome_title {
        font-size: 20px;
        line-height: 30px;
        max-width: 270px;
    }

    .desk .timer .welcome_title,
    .tab .timer .welcome_title {
        font-size: 24px;
        line-height: 35px;
        max-width: 350px;
    }

    .welcome_title .title_sm {
        font-size: 15px;
        line-height: 22px;
    }

    .desk .welcome_title .title_sm,
    .tab .welcome_title .title_sm {
        font-size: 18px;
        line-height: 25px;
        margin-top: 15px;
    }

    .timer .block_note {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        max-width: 300px;
        margin-top: 10px;
    }

    .desk .timer .block_note,
    .tab .timer .block_note {
        font-size: 15px;
        line-height: 23px;
        max-width: 380px;
    }

    .final_note {
        background: #f5f5f6;
        border-radius: 5px;
        padding: 15px;
        color: #444;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        margin-top: 20px;
        max-width: 300px;
    }

    .tab .final_note,
    .desk .final_note {
        padding: 17px 20px;
        font-size: 13px;
        line-height: 20px;
        margin-top: 30px;
        max-width: 380px;
    }

    /*------- Workout --------*/
    .plan_wrapper {
        padding: 20px 0 40px 0;
    }

    .plan_wrapper .swiper-wrapper {
        overflow: hidden;
    }

    .plan_wrapper .swiper-slide {
        width: 100% !important;
    }

    .plan_wrapper :deep(.swiper-pagination-bullet-active) {
        background: var(--var-element-color);
    }

    .plan_wrapper :deep(.swiper-button-next),
    .plan_wrapper :deep(.swiper-button-prev) {
        top: 20px;
        width: 20px;
        height: 20px;
        margin: 0;
    }

    .plan_wrapper :deep(.swiper-button-next.swiper-button-disabled),
    .plan_wrapper :deep(.swiper-button-prev.swiper-button-disabled) {
        opacity: 0;
    }

    .plan_wrapper :deep(.swiper-button-next) {
        right: 0;
    }

    .plan_wrapper :deep(.swiper-button-next:after) {
        content: '\f30b';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }

    .plan_wrapper :deep(.swiper-button-prev) {
        left: 0;
    }

    .plan_wrapper :deep(.swiper-button-prev:after) {
        content: '\f30a';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }

    .block_title {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }

    .desk .block_title,
    .tab .block_title {
        font-size: 18px;
        line-height: 23px;
        padding-bottom: 15px;
    }

    .exercise_item .plan_list {
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }

    .desk .exercise_item .plan_list,
    .tab .exercise_item .plan_list {
        padding: 15px 30px;
    }

    .exercise_item .plan_list .block_type {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }

    .desk .exercise_item .plan_list .block_type,
    .tab .exercise_item .plan_list .block_type {
        font-size: 13px;
        line-height: 17px;
    }

    .exercise_item .plan_list .plan_info {
        font-size: 10px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .desk .exercise_item .plan_list .plan_info,
    .tab .exercise_item .plan_list .plan_info {
        font-size: 13px;
        line-height: 17px;
    }

    .exercise_item .plan_list h4 span {
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }

    .exercise_item .plan_list ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px 0;
    }

    .desk .exercise_item .plan_list ul,
    .tab .exercise_item .plan_list ul {
        gap: 20px;
        padding: 15px 0 20px 0;
    }

    .exercise_item .plan_list ul li {
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        cursor: pointer;
    }

    .desk .exercise_item .plan_list ul li,
    .tab .exercise_item .plan_list ul li {
        gap: 20px;
    }

    .exercise_item .plan_list ul li .video_area {
        width: 33%;
    }

    .exercise_item .plan_list ul li .info_wpr {
        width: calc(67% - 12px);
        padding: 10px 0;
    }

    .desk .exercise_item .plan_list ul li .info_wpr,
    .tab .exercise_item .plan_list ul li .info_wpr {
        width: calc(67% - 20px);
        padding: 15px 0;
    }

    .exercise_item .plan_list ul li .info_wpr h4 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 7px;
    }

    .desk .exercise_item .plan_list ul li .info_wpr h4,
    .tab .exercise_item .plan_list ul li .info_wpr h4 {
        font-size: 15px;
        line-height: 22px;
    }

    .exercise_item .plan_list ul li .info_wpr h6 {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .desk .exercise_item .plan_list ul li .info_wpr h6,
    .tab .exercise_item .plan_list ul li .info_wpr h6 {
        font-size: 13px;
        line-height: 18px;
    }

    .exercise_item .plan_list ul li .info_wpr h6 span {
        padding: 0 5px;
    }

    .exercise_item .plan_list ul li .info_wpr h6 span:not(:last-child) {
        border-right: 1px solid #d9d9d9;
    }

    .video_wpr {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


    /*------- details --------*/
    .exercise_details .ex_title {
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 20px;
        position: relative;
    }

    .exercise_details .ex_title .back_btn {
        font-size: 15px;
        color: #999;
        position: absolute;
        left: 0;
        top: 5px;
        cursor: pointer;
    }

    .exercise_details .info_wpr {
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    .exercise_details .info_wpr h6 {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .desk .exercise_details .info_wpr h6,
    .tab .exercise_details .info_wpr h6 {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 0;
        font-weight: 400;
    }

    .exercise_details .info_wpr h6 span {
        padding: 4px 10px;
        background: #e9e9e9;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
    }

    .desk .exercise_details .info_wpr h6 span,
    .tab .exercise_details .info_wpr h6 span {
        padding: 7px 15px;
        background: #e9e9e9;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
    }

    .exercise_details .instruction,
    .exercise_list .instruction {
        padding: 30px 0 0 15px;
    }

    .desk .exercise_details .instruction,
    .desk .exercise_list .instruction,
    .tab .exercise_details .instruction,
    .tab .exercise_list .instruction {
        padding: 50px 0 0 15px;
    }

    .exercise_details .instruction h4.headline,
    .exercise_list .instruction h4.headline {
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 12px;
        position: relative;
        display: flex;
    }

    .exercise_details .instruction h4:before,
    .exercise_list .instruction h4:before {
        position: absolute;
        height: 20px;
        content: '';
        left: -15px;
        top: 0;
        border-left: 3px solid var(--var-element-color);
    }

    .timer .exercise_details .instruction h4:before,
    .timer .exercise_list .instruction h4:before {
        display: none;
    }

    .desk .exercise_details .instruction h4.headline,
    .desk .exercise_list .instruction h4.headline,
    .tab .exercise_details .instruction h4.headline,
    .tab .exercise_list .instruction h4.headline {
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 15px;
    }

    .desk .exercise_details .instruction h4:before,
    .desk .exercise_list .instruction h4:before,
    .tab .exercise_details .instruction h4:before,
    .tab .exercise_list .instruction h4:before {
        height: 22px;
    }

    .exercise_details button.calc_btn {
        height: 24px;
        padding: 0 10px;
        background: #f5f5f5;
        font-size: 11px;
        line-height: 14px;
        border-radius: 3px;
        flex-grow: 0;
        margin-left: auto;
        position: relative;
    }

    .desk .exercise_details button.calc_btn,
    .tab .exercise_details button.calc_btn {
        height: 30px;
        font-size: 13px;
        line-height: 16px;
    }

    .exercise_details button.calc_btn.blink {
        background: #fff;
    }

    .exercise_details button.calc_btn.blink:after {
        position: absolute;
        content: '';
        border-radius: 3px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 0.5s infinite alternate;
    }

    .exercise_details .instruction h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        text-align: center;
        padding-bottom: 10px;
    }

    .desk .exercise_details .instruction h4,
    .tab .exercise_details .instruction h4 {
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 20px;
        position: relative;
    }

    .exercise_details .instruction label {
        text-align: center;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        display: block;
        padding-bottom: 5px;
    }

    .desk .exercise_details .instruction label,
    .tab .exercise_details .instruction label {
        font-size: 15px;
        line-height: 22px;
        padding-bottom: 10px;
    }

    .exercise_details .instruction h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 10px 0 15px 0;
    }

    .desk .exercise_details .instruction h5,
    .tab .exercise_details .instruction h5 {
        font-size: 15px;
        line-height: 22px;
    }

    .exercise_details .instruction p,
    .exercise_list .instruction p {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .desk .exercise_details .instruction p,
    .desk .exercise_list .instruction p,
    .tab .exercise_details .instruction p,
    .tab .exercise_list .instruction p {
        font-size: 15px;
        line-height: 23px;
    }

    .exercise_details .instruction .video_wpr {
        border-radius: 6px;
    }

    .activity {
        margin: 30px 0;
        padding-top: 15px;
    }

    .activity table {
        width: 100%;
        border-spacing: 8px;
        background: var(--var-block-bg-color);
        border-radius: 5px;
        padding: 0 5px 5px 5px;
    }

    .desk .activity table,
    .tab .activity table {
        padding: 0 10px 10px 10px;
    }

    .activity table th,
    .activity table td {
        padding: 10px 5px;
        border-radius: 3px;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
    }

    .desk .activity table th,
    .desk .activity table td,
    .tab .activity table th,
    .tab .activity table td {
        font-size: 13px;
        line-height: 18px;
    }

    .activity table th {
        width: 23%;
    }

    .activity table td {
        width: 23%;
        background: #fff;
        color: #777;
    }

    .activity table th.set,
    .activity table td.set {
        width: 40px;
        background: var(--var-block-bg-color);
    }

    .activity table td input {
        width: 100%;
        text-align: center;
        font-size: 11px;
        background: #fff;
    }

    .desk .activity table td input,
    .tab .activity table td input {
        font-size: 13px;
    }

    .activity table td input[type=number].no_spinner {
        width: 25px;
    }

    .activity table td input[type=number].no_spinner::-webkit-outer-spin-button,
    .activity table td input[type=number].no_spinner::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .exercise_details .action_wpr {
        gap: 15px;
        position: relative;
        margin: 30px 0 20px 0;
    }

    .exercise_details button {
        flex-grow: 1;
        height: 45px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
    }

    .desk .exercise_details button,
    .tab .exercise_details button {
        height: 55px;
        font-size: 16px;
        line-height: 20px;
    }

    .exercise_details .incomplete_btn {
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }

    .exercise_details .complete_btn {
        border: 1px solid var(--var-submit-btn-color);
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
    }

    .incomplete_sec {
        position: absolute;
        width: 300px;
        padding: 5px 15px;
        bottom: 100%;
        left: 0;
        margin-bottom: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 4;
    }

    .incomplete_sec:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: 50px;
        top: calc(100% - 1px);
    }

    .incomplete_sec .action_wpr {
        gap: 10px;
        justify-content: flex-end;
        margin: 5px 0 15px 0;
    }

    .incomplete_sec button {
        flex-grow: 0;
        height: 30px;
        border-radius: 3px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        padding: 0 15px;
    }

    .incomplete_sec .cancel_rsn {
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }

    .incomplete_sec .save_rsn {
        border: 1px solid #2c3e50;
        background: #2c3e50;
        color: #fff;
    }


    /*-------- Complete -------*/
    .workout_completion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .desk .workout_completion,
    .tab .workout_completion {
        padding-top: 30px;
    }

    .finish_timer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
    }

    .desk .finish_timer,
    .tab .finish_timer {
        padding-bottom: 30px;
    }

    .finish_timer h2 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #121525;
        padding: 10px 0;
        border-bottom: 1px solid #d9d9d9;
    }

    .total_timer {
        display: flex;
        gap: 10px;
        font-size: 22px;
        line-height: 28px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
    }

    .total_timer label {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .total_timer label span {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .ratings ul {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
    }

    .ratings ul li {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        padding: 5px 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .desk .ratings ul li,
    .tab .ratings ul li {
        gap: 20px;
        padding: 10px 0;
        font-size: 15px;
        line-height: 20px;
    }

    .ratings ul li span {
        display: flex;
        flex-direction: row-reverse;
        gap: 5px;
        color: var(--var-element-color);
    }

    /* ----- for ratings ---------- */

    .ratings ul li span input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
    }

    .desk .ratings ul li span input,
    .tab .ratings ul li span input {
        width: 20px;
        height: 20px;
    }

    .ratings ul li span input::before {
        content: '\f005';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        font-size: 13px;
        color: var(--var-element-color);
        transition: 0.5s;
    }

    .desk .ratings ul li span input::before,
    .tab .ratings ul li span input::before {
        font-size: 15px;
    }

    .ratings input:hover~input::before,
    .ratings input:hover::before,
    .ratings input:checked~input::before,
    .ratings input:checked::before {
        font-weight: 900;
    }

    .workout_completion .setting_wpr {
        max-width: 300px;
        width: 100%;
    }

    .desk .workout_completion .setting_wpr,
    .tab .workout_completion .setting_wpr {
        max-width: 400px;
    }

    .workout_completion .field_wpr textarea {
        height: 100px;
    }

    .desk .workout_completion .field_wpr textarea,
    .tab .workout_completion .field_wpr textarea {
        height: 150px;
    }


    /*-------- Complete -------*/

    .workout_logged {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .workout_logged .success {
        color: var(--var-element-color);
        font-size: 30px;
        padding: 15px 0 30px 0;
    }

    .workout_logged h4 {
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 5px;
    }

    .desk .workout_logged h4,
    .tab .workout_logged h4 {
        font-size: 22px;
        line-height: 35px;
        padding-bottom: 15px;
    }

    .workout_logged p {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .desk .workout_logged p,
    .tab .workout_logged p {
        font-size: 15px;
        line-height: 22px;
    }

    .workout_name {
        padding: 15px 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 30px 0 15px;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        max-width: 250px;
        width: 100%;
        text-align: center;
    }

    .desk .workout_name,
    .tab .workout_name {
        padding: 20px 25px;
        max-width: 350px;
        font-size: 18px;
        line-height: 25x;
    }

    .reminder {
        padding: 15px 0;
    }

    .reminder_day {
        padding: 15px 0;
    }

    .reminder_day label {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        padding: 5px 0;
        cursor: pointer;
    }

    .reminder_day label span {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid var(--var-element-color);
        position: relative;
        margin-top: 3px;
    }

    .reminder_day label span:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: var(--var-element-color);
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .reminder_day label input[type=radio]:checked~span:after {
        transform: scale(1);
    }

    .time_line {
        max-width: 250px;
        width: 100%;
    }

    .workout_logged .form_grp :deep(.multiselect),
    .workout_logged .form_grp .field_wpr input {
        height: 35px;
        min-height: 35px;
    }

    .workout_logged .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 75px;
    }

    .workout_logged :deep(.multiselect-single-label) {
        padding: 0 20px 0 15px;
    }

    .workout_logged :deep(.multiselect-caret) {
        margin-right: 10px;
    }

    .workout_logged :deep(.multiselect-single-label-text) {
        font-size: 11px;
    }

    .desk .week_days_list label span.weekdays,
    .tab .week_days_list label span.weekdays {
        color: var(--var-element-text-color);
    }

    @keyframes clr_anim {
        0% {
            opacity: 0.05;
        }

        100% {
            opacity: 0.2;
        }
    }

    @keyframes clr_anim2 {
        0% {
            opacity: 0.2;
        }

        100% {
            opacity: 0.5;
        }
    }

    @keyframes mover {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-10px);
        }
    }

    @keyframes mover2 {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-7px);
        }
    }

    .exercise_info .workout-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .exercise_info .workout-content sup {
        padding: 4px;
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
        border-radius: 5px;
        font-size: 9px;
        font-weight: 600;
    }

    .exercise_info .workout-content .start_btn {
        height: 40px;
        min-width: 50px;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 21px;
        margin: 0;
    }

    :deep(.cell .dashboard_content),
    :deep(.tab .dashboard_content) {
        display: block;
    }

    .cell .form_area_video {
        max-width: 800px;
        margin: 0px;
        flex: 1;
    }

    .form_area_video {
        max-width: 800px;
        margin: 20px auto 50px auto;
        flex: 1;
    }

    .dashboard_content .form_area_video .section_item {
        padding: 0px 0px 25px 0px;
    }

    .video_wpr{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    :deep(.section_item .video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
    }

    :deep(.section_item .video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
</style>
